import {STATUS_MESSAGE} from "@/store/actions.type";
import store from '@/store'

export default {
  install(Vue) {
    Vue.prototype.$notification = text => {
      store.dispatch(STATUS_MESSAGE, text)
    };
  }
};

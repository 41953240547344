import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import JwtService from "@/common/jwt.service"
import { HOST, API_PATH } from "@/config"

const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = HOST + API_PATH
  },

  setHeader () {
    if (JwtService.getToken()) {
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`
    }
  },

  query (resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`ApiService ${error}`)
    })
  },

  get (resource, slug = "") {
    this.setHeader()
    return Vue.axios.get(`${resource}${slug ? '/' + slug : ''}`)
      .catch(error => {
        throw new Error(`ApiService ${error}`)
      })
      .then(data => data.data)
  },

  post(resource, params) {
    this.setHeader()
    return Vue.axios.post(`${resource}`, params).then(data => data.data)
  },

  update(resource, slug, params) {
    this.setHeader()
    return Vue.axios.put(`${resource}${slug ? '/' + slug : ''}`, params).then(data => data.data)
  },

  put(resource, params) {
    this.setHeader()
    return Vue.axios.put(`${resource}`, params).then(data => data.data)
  },

  delete(resource) {
    this.setHeader()
    return Vue.axios.delete(resource)
      .catch(error => {
        throw new Error(`ApiService ${error}`)
      })
      .then(data => data.data)
  }
}

export default ApiService

export const DevicesService = {
  get(region_id = false) {
    return ApiService.get('device' + (region_id ? ('?region_id=' + region_id) : ''))
  },
  add(device) {
    return ApiService.post('device', device)
  },
  delete(id) {
    return ApiService.delete(`device/${id}`)
  },
  update(id, device) {
    return ApiService.put(`device/${id}`, device)
  }
}

export const UsersService = {
  workers(worker_type_id = false, region_id = false) {
    return ApiService.get('user?role=worker' + (worker_type_id ? ('&worker_type_id=' + worker_type_id) : '') + (region_id ? ('&region_id=' + region_id) : ''))
  },
  companies() {
    return ApiService.get('user?role=company')
  },
  managers () {
    return ApiService.get('user?role=manager')
  },
  filial_managers () {
    return ApiService.get('user?role=filial_manager')
  },
  coordinators() {
    return ApiService.get('user?role=coordinator')
  },
  add(user, role) {
    return ApiService.post(`user?role=${role}`, user)
  },
  delete(id) {
    return ApiService.delete(`user/${id}`)
  },
  update(id, user) {
    return ApiService.put(`user/${id}`, user)
  },
  // Отметить пользователя не-водителем
  not_a_driver () {
    return ApiService.post('not_a_driver')
  }
}

export const PricesService = {
  get() {
    return ApiService.get('prices')
  },
  add(price) {
    return ApiService.post('prices', price)
  },
  update(price) {
    return ApiService.put('prices', price)
  },

  // Удаление цены
  delete (priceId) {
    return ApiService.delete(`prices/${priceId}`)
  }
}

export const MetersService = {
  get () {
    return ApiService.get('meters')
  },
  add (meter) {
    return ApiService.post('meters', meter)
  },
  update (meterId, meter) {
    return ApiService.put(`meters/${meterId}`, meter)
  },
  delete (meterId) {
    return ApiService.delete(`meters/${meterId}`)
  }
}

export const MeterBrandsService = {
  get () {
    return ApiService.get('meter_brands')
  },
  add (meter) {
    return ApiService.post('meter_brands', meter)
  },
  update (meterId, meter) {
    return ApiService.put(`meter_brands/${meterId}`, meter)
  },
  delete (meterId) {
    return ApiService.delete(`meter_brands/${meterId}`)
  }
}

export const ListsService = {
  get(lists = []) {
    return ApiService.get(`lists?lists=${lists.join(',')}`)
  }
}

export const VerificationsService = {
  get(id = false, query = {}) {
    const queryParams = new URLSearchParams()
    for(const [key] of Object.entries(query)) {
      if( query[key] !== null ) {
        queryParams.append(key, query[key])
      }
    }

    return !id ? ApiService.get(`verification?${queryParams.toString()}`) : ApiService.get(`verification/${id}`)
  },
  getByDates() {
    return ApiService.get('verification?by_date=1')
  },
  // Поверки с группировкой по дате для исполнителей
  getByDatesForWorker(page) {
    return ApiService.get(`verifications?page=${page}`)
  },
  add(verification) {
    return ApiService.post('verification', verification)
  },
  update(id, verification) {
    return ApiService.put(`verification/${id}`, verification)
  },
  updateImages(id, formData) {
    return ApiService.post(`verification/${id}/images`, formData)
  },
  // Поворот изображения
  rotateImage(id, rotate) {
    return ApiService.put(`verification_images/${id}/rotate?rotate=${rotate}`)
  },
  delete(id) {
    return ApiService.delete(`verification/${id}`)
  },
  // История изменения заявки
  getHistory (id) {
    return ApiService.get(`verifications/${id}/history`)
  },
  // Отчёт для директора
  getReport () {
    return ApiService.get('verification/report')
  },

  // Обновление координат
  updateCoordinates (id, verification) {
    return ApiService.put(`verification/${id}/coordinates`, verification)
  },

  // Обновление маршрута
  updateRoute(id, routeId) {
    return ApiService.put(`verification/${id}/route_put`, {
      route_id: routeId
    })
  },

  // Обновление статуса
  updateStatus(id, status) {
    return ApiService.put(`verification/${id}/status`, {
      status: status
    })
  },

  // Комментарий
  addCoordinatorComment(id, comment) {
    return ApiService.put(`verification/${id}/coordinator_comment`, {
      comment: comment
    })
  }
}

// Работа с маршрутными листами
export const RoutesService = {
  // Получение маршрутных листов
  get (id = false, query = {}) {
    let params = []

    if (query.verification_date) {
      params.push(`date=${query.verification_date}`)
    }
    if (query.type_id) {
      params.push(`type_id=${query.type_id}`)
    }

    return !id ? ApiService.get(`routes?${params.join('&')}`) : ApiService.get(`routes/${id}`)
  },

  //
  add (route) {
    return ApiService.post('routes', route)
  },

  //
  update(id, route) {
    return ApiService.put(`routes/${id}`, route)
  },

  //
  delete(id) {
    return ApiService.delete(`routes/${id}`)
  },

  //
  attachVerifications (id, verifications) {
    return ApiService.put(`routes/${id}/attach_verifications?verifications=${verifications}`)
  },

  // перестановка адресов поверок в маршрутном листе
  reorderVerifications (id, verificationOldPriority, verificationNewPriority) {
    return ApiService.put(`routes/${id}/reorder_verifications?verification_old_priority=${verificationOldPriority}&verification_new_priority=${verificationNewPriority}`)
  },
}

// Работа с пробегом
export const MileageService = {
  // Получение пробегов
  get (id = false, user_id = false) {
    return !id ? ApiService.get('mileages' + (user_id ? '?user_id=' + user_id : '')) : ApiService.get(`mileages/${id}`)
  },

  // Сохранение пробега
  add (mileage) {
    return ApiService.post('mileages', mileage)
  }
}

// Работа с изображениями
export const ImageService = {
  // Сохранение изображения
  upload (image) {
    return ApiService.post('images', image)
  }
}

export const TypesService = {
  // Типы доходов и расходов
  getIncomeExpenses() {
    return ApiService.get('income_expenses_types')
  }
}

// Доходы и расходы
export const IncomeExpensesService = {
  getIncomeExpenses(params = {}) {
    return ApiService.query('income_expenses', { params: params })
  },

  amountIncomeExpenses (params = {}) {
    return ApiService.query('income_expenses/amount', { params: params })
  },

  store(incomeExpenses) {
    return ApiService.post('income_expenses', incomeExpenses)
  },

  update(incomeExpenses) {
    return ApiService.put(`income_expenses/${incomeExpenses.id}`, incomeExpenses)
  }
}

<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>

    <TheStatusMessage/>
  </v-app>
</template>

<script>
import TheStatusMessage from '@/components/TheStatusMessage'

export default {
  components: {
    TheStatusMessage
  }
}
</script>

<style>
.verificationForm .row {
  margin: 4px -12px -12px -12px !important;
  min-height: 70px;
}

.verificationForm .col, .verificationForm .col-12 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.verificationForm .addressWrapper {
  border: 1px solid #cccccc;
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Heading from "@/components/Heading"
import NotificationPlugin from '@/plugins/notification'
import DateFilter from "@/filters/date"
import ApiService from "./common/api.service"

Vue.config.productionTip = false

ApiService.init()

Vue.component('Heading', Heading)
Vue.filter('date', DateFilter)
Vue.use(NotificationPlugin)

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from "vue2-google-maps/dist/components/cluster";
Vue.component('GmapCluster', GmapCluster);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAombYVhbGC_N_wyuttj408DsINEnYBwio',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

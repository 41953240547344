<template>
  <v-snackbar
      right
      v-model="displayBar"
      class="text-center"
  >
    {{ statusMessage }}

<!--    <template v-slot:action="{ attrs }">-->
<!--      <v-icon-->
<!--          color="blue"-->
<!--          text-->
<!--          v-bind="attrs"-->
<!--          @click="onCloseClick"-->
<!--      >-->
<!--        close-->
<!--      </v-icon>-->
<!--    </template>-->
  </v-snackbar>
</template>

<script>
import {mapGetters} from 'vuex'
// import {STATUS_MESSAGE} from "@/store/actions.type";

export default {
  data() {
    return {
      timeout: 5000
    }
  },

  // methods: {
  //   onCloseClick() {
  //     this.$store.dispatch(STATUS_MESSAGE, null)
  //   }
  // },

  computed: {
    ...mapGetters([
        'statusMessage'
    ]),
    displayBar() {
      return this.statusMessage !== null
    }
  }
}
</script>

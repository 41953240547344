import ApiService from "@/common/api.service"
import JwtService from "@/common/jwt.service"

import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_USER,
  // CHECK_AUTH
} from "../actions.type"

import {AUTH_SET, AUTH_PURGE, SET_ERROR} from "../mutations.type"

const state = {
  error: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
}

const getters = {
  user(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  }
}

const actions = {
  [AUTH_LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/login", credentials)
        .then(({data}) => {
          context.commit(AUTH_SET, {
            user: data.user,
            token: data.token
          })
          resolve(data)
        })
        .catch(({response}) => {
          context.commit(SET_ERROR, response.data.error)
        })
    })
  },
  [AUTH_GET_USER](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("auth/user")
        .then(({data}) => {
          context.commit(AUTH_SET, {
            user: data.user
          })
          resolve(data)
        })
        .catch((data) => {
          context.commit(AUTH_PURGE)
          reject(data)
        })
    })
  },
  [AUTH_LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.post("auth/logout")
        .then(({data}) => {
          context.commit(AUTH_PURGE)
          resolve(data)
        })
    })
  }
}

const mutations = {
  [SET_ERROR](state, error) {
    state.error = error
  },
  [AUTH_SET](state, data) {
    state.isAuthenticated = true
    state.user = data.user
    state.error = null
    if( data.token ) {
      JwtService.saveToken(data.token)
    }
  },
  [AUTH_PURGE](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = null
    JwtService.destroyToken()
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

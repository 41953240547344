import {STATUS_MESSAGE} from "@/store/actions.type"
import {SET_STATUS_MESSAGE} from "@/store/mutations.type"

const state = {
  statusMessage: null,

  // Фильтры поверок
  verificationQuery: {
    region_id: null,
    district_id: null,
    verification_date_from: null,
    verification_date_to: null,
    meter_status_id: null,
    search: null,
    type_id: null,
    verifier_id: null,
    sortBy: ['verification_date'],
    sortDesc: [true],
    itemsPerPage: 50,
    page: 1
  }
}

const getters = {
  statusMessage(state) {
    return state.statusMessage
  }
}

const actions = {
  [STATUS_MESSAGE](context, message) {
    context.commit(SET_STATUS_MESSAGE, message)

    setTimeout(() => {
      context.commit(SET_STATUS_MESSAGE, null)
    }, 3000)
  }
}

const mutations = {
  [SET_STATUS_MESSAGE](state, message) {
    state.statusMessage = message
  },

  SET_VERIFICATION_QUERY (state, query) {
    state.verificationQuery = query
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
